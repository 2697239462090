<template>
  <div>
    <m-bread-crumb/>

<section class="contact spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="contact__text">
                        <div class="section-title">
                            <h2>Desconto de Livrança</h2>
                            <p>São operações de crédito de médio e longo prazo (superiores a 1 ano) com total flexibilidade no prazo, montante, e forma de reembolso.
                            O seu negócio só precisa da oportunidade certa para crescer e de contar com as soluções de financiamento mais adequadas. 
                            </p>
                        </div>
                       
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-6">
                    <div class="car__details__pic">
                        <div class="car__details__pic__large">
                            <img class="car-big-img" src="img/cars/details/cd-27.png" alt="">
                        </div>
                        
                    </div>
                </div>
                
                
            </div>
            
        <div class="col-lg-12" >
            <h2 style="text-align: center">Vantagens</h2>
            <br>
            <br>
        <div class="about__feature">
                <div class="row">
                    
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="about__feature__item">
                            <img src="img/about/af-1.png" alt="">
                            <h5>Entrega Rapida</h5>
                            <p>Fazemos desembolso ate 3 dias uteis.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="about__feature__item">
                            <img src="img/about/af-2.png" alt="">
                            <h5>Qualidade de dados</h5>
                            <p>Somos responsaveis pela qualidade de dados.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="about__feature__item">
                            <img src="img/about/af-3.png" alt="">
                            <h5>Taxas fixas</h5>
                            <p>Temos taxas fixas ate ultima prestacao.</p>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
            
            
        
            
        </div>
        
      
    
   
    </section>
    
    
 
<section class="car-details spad" id="creditopessoalrapido">
<div class="container">  
<div class="row">
  
    
<div id="accordion" class="col-lg-12">
  <div class="card">
    <div class="card-header" id="headingOne">
      <h5 class="mb-0">
        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          Finalidade
        </button>
      </h5>
    </div>

    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Tem por finalidade, garantir a execução de obrigações assumidas pelo Cliente (Ordenador) perante terceiros (Beneficiários).
        </ul>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingTwo">
      <h5 class="mb-0" >
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> 
          Público – Alvo
        </button>
              
      </h5>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
            Esta solução esta direcionada para Empresas em Nome Individual (ENI's) e Colectivo.
        </ul>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          Vantagens
        </button>
      </h5>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Apoio a actividade economica;
                <br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Acesso a melhores condicoes financeiras;<br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Garantia das obrigacoes contractuais; <br />
        

                
        </ul>
      </div>
    </div>
  </div>
<div class="card">
    <div class="card-header" id="headingFour">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
          Prazo
        </button>
      </h5>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
            Máximo 12 Meses (1 Ano) com flexibilidade.<br />    
        </ul>
      </div>
    </div>
  </div>
<div class="card">
    <div class="card-header" id="headingFive">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
          Tipo de Garantias Emitidas> (Características)
        </button>
      </h5>
    </div>
    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
         
        <div class="card card-body">
                <ul>
                    
                                <p>
                                    <span><b>Garantias Financeiras:</b></span><br>
                                    <i class="fa fa-chevron-circle-right" style="color:red;"></i>Alfandegárias - para caucionamento de desalfandegamento de mercadorias; Caucionamento de armazém afiançado;
                        <br>
		                              <i class="fa fa-chevron-circle-right" style="color:red;"></i> Aquisição de mercadorias ou bens a prazo;<br>
                    <span><b>Garantias Técnicas:</b></span><br>
		                              <i class="fa fa-chevron-circle-right" style="color:red;"></i> Participação em Concurso Público/Privado; <br>
                                    </p>
                                    
                </ul>
        </div>
     
        
    </div>
  </div>
<div class="card">
    <div class="card-header" id="headingSix">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
          Requisitos e obrigações do proponente
        </button>
      </h5>
    </div>
    <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
         
        <div class="card card-body">
                <ul>
                    <p><i class="fa fa-chevron-circle-right" style="color:red;"></i>Licenciado para o exercício da actividade comercial (Alvará);<br>
		                              <i class="fa fa-chevron-circle-right" style="color:red;"></i> Obrigações fiscais e contribuições da Segurança Social em dia;<br>
		                              <i class="fa fa-chevron-circle-right" style="color:red;"></i> Apresentar Caderno de encargos (em caso de garantia para concurso);<br>
                                    <i class="fa fa-chevron-circle-right" style="color:red;"></i> Apresentar o Contrato com o beneficiário (que origina a Garantia);<br/>
                                    <i class="fa fa-chevron-circle-right" style="color:red;"></i> Comprometer-se a cumprir com o estabelecido no Contrato de Emissão da Garantia Bancária.<br>
		                           
                                   </p>
                </ul>
                            </div>    
                        </div>
                    </div>    
    
                </div>
        
        
    </div>
    </div>
    

</section> 

   
  </div>
</template>

<script>
import MBreadCrumb from "@/components/Layout/BreadCrumb";

export default {
  name: "Desconto de Livrança",
  components: { MBreadCrumb }
}
</script>

<style scoped>

</style>